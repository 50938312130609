function Schedule() {
  return (
    <div>
      <span className="text-xl sm:text-2xl font-extrabold">선지원 후고민, 아시죠?</span>
      <div className="flex justify-center">
        <img alt="일정" className="mt-5" src="일정.png"></img>
      </div>

    </div>
  );
}

export default Schedule;
